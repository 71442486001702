import * as Sentry from "@sentry/react";
import './bootstrap';
import '../css/app.css';
import '../css/tags.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';
// const appEnv = import.meta.env.VITE_APP_ENV || 'production';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        Sentry.init({
            dsn: "https://608da15079c262abe60de387bea86767@o4507964846047232.ingest.us.sentry.io/4507964848144384",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            enabled: import.meta.env.VITE_IS_PRODUCTION,
        });

        const root = createRoot(el);

        root.render(<App {...props} />);
    },
    progress: {
        color: '#724CF9',
    },
});
